import { graphql } from 'gatsby';
import React from 'react';
import loadable from '@loadable/component';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/SEO';
import Hero from '../components/home/Hero';
const About = loadable(() => import('../components/home/About'));
const PracticeAreas = loadable(() => import('../components/home/PracticeAreas'));
const Pitch = loadable(() => import('../components/home/Pitch'));
const Partners = loadable(() => import('../components/home/Partners'));
const Testimonials = loadable(() => import('../components/home/Testimonials'));
const ContactBlog = loadable(() => import('../components/home/ContactBlog'));
const OurCases = loadable(() => import("../components/home/OurCases"));

const index = ({ data }) => {

  const { siteMetadata } = data.site;

  const practiceAreas = data.allMdx.edges.map(edge => edge.node.frontmatter);

  const schema = {
    "@context": "https://schema.org",
    "@type": "LegalService",
    "name": siteMetadata.title,
    "url": siteMetadata.siteUrl,
    "logo": `${siteMetadata.siteUrl}/${siteMetadata.logo}`,
    "telephone": siteMetadata.phone,
    "email": siteMetadata.email,
    "employee": [
      {
        "@type": "Person",
        "name": "Darryl J. Dreyer",
        "jobTitle": "Partner",
        "email": "darryldreyer@aol.com",
        "telephone": "914.805.2562",
        "sameAs": "https://dreyerinjurylaw.com/about/darryl-j-dreyer"
      },
      {
        "@type": "Person",
        "name": "Sarah Dreyer",
        "jobTitle": "Partner",
        "email": "dreyerlawoffices@aol.com",
        "telephone": "845.787.7777",
        "sameAs": "https://dreyerinjurylaw.com/about/sarah-dreyer"
      }
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": siteMetadata.address,
      "addressLocality": siteMetadata.city,
      "addressRegion": siteMetadata.state,
      "postalCode": siteMetadata.zipcode,
      "addressCountry": "US"
    },
    "description": siteMetadata.description,
    "sameAs": [
      `https://www.facebook.com/${siteMetadata.facebook}`,
      `https://twitter.com/${siteMetadata.twitter}`,
      `https://www.instagram.com/${siteMetadata.instagram}`,
      `https://www.linkedin.com/company/${siteMetadata.linkedin}`
    ],
    "makesOffer": practiceAreas.map(area => ({
      "@type": "Offer",
      "itemOffered": {
        "@type": "Service",
        "name": area.title,
        "sameAs": `https://dreyerinjurylaw.com/${area.canon}`
      }
    })),
    "areaServed": [
      {
        "@type": "Place",
        "name": "Newburgh, NY"
      },
      {
        "@type": "Place",
        "name": "Goshen, NY"
      },
      {
        "@type": "Place",
        "name": "Middletown, NY"
      },
      {
        "@type": "Place",
        "name": "Poughkeepsie, NY"
      },
      {
        "@type": "Place",
        "name": "Beacon, NY"
      },
      {
        "@type": "Place",
        "name": "Kingston, NY"
      },
      {
        "@type": "Place",
        "name": "Orange County, NY"
      },
      {
        "@type": "Place",
        "name": "Dutchess County, NY"
      },
      {
        "@type": "Place",
        "name": "Ulster County, NY"
      },
      {
        "@type": "Place",
        "name": "Hudson Valley, NY"
      }
    ]    
  };  

  return (
    <Layout>
      <Seo
        title='Newburgh Personal Injury Attorney'
        schema={schema}
      />
      <Hero />
      <OurCases data={data.allResultsJson} />
      <Partners />
      <PracticeAreas />
      <About />
      <ContactBlog />
      <Pitch />
      <Testimonials data={data.allTestimonialsJson} />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
        logo
        phone
        email
        address
        city
        state
        zipcode
        facebook
        twitter
        instagram
        linkedin
      }
    }
    allMdx(
      filter: {fileAbsolutePath: {glob: "**/src/data/practice-areas/*"}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            canon
          }
        }
      }
    }
    allResultsJson(limit: 9) {
      edges {
        node {
          award
          category
          id
          narrative
        }
      }
    }
    allTestimonialsJson(limit: 1) {
      edges {
        node {
          id
          author
          excerpt
        }
      }
    }
  }
`

export default index